import { GameObj } from "kaboom";

export default class Tank {
	x: number;
	y: number;
	body: GameObj;
	barrel: GameObj;
	sight: GameObj;
	click: () => void;

	// The angle of the barrel.
	barrelAngle = 0;
	setBarrelAngle = angle => {
		this.barrelAngle = angle;
		this.barrel.angle = angle;
		this.sight.angle = angle;
	};

	// Multiplied by the frame delta time to get the added rotation of the barrel (in degrees) each frame.
	rotationSpeed = 100;

	// Should the barrel be rotating clockwise?
	clockwise = true;

	// Should the barrel be rotating?
	shouldRotate = true;

	// The number of milliseconds to stop the barrel rotating upon firing.
	fireRotatePause = 50;

	// The speed of the bullet in pixels per second.
	bulletSpeed = 1500;

	constructor(position, sizeMultiplier) {
		this.x = position.x;
		this.y = position.y;
		this.body = add([
			sprite("tankBodyGreenOutline"),
			pos(this.x, this.y),
			scale(sizeMultiplier),
			// @ts-ignore
			origin("center"),
			area(),
			z(1)
		]);
		this.barrel = add([
			sprite("tankGreenBarrel3"),
			pos(this.x, this.y - 7.5 * sizeMultiplier),
			scale(sizeMultiplier),
			rotate(this.barrelAngle),
			// @ts-ignore
			origin("top"),
			z(3),
			"barrel"
		]);
		this.sight = add([
			rect(3, 20000),
			pos(this.x, this.y - 7.5 * sizeMultiplier),
			rotate(this.barrelAngle),
			// @ts-ignore
			origin("top"),
			color(RED),
			opacity(0.5),
			z(2),
			"sight"
		]);

		this.click = () => {
			this.clockwise = !this.clockwise;
			add([
				sprite("shotThin"),
				pos(this.barrel.pos.x, this.barrel.pos.y),
				scale(sizeMultiplier),
				// @ts-ignore
				origin("center"),
				rotate(this.barrelAngle + 180),
				z(-1),
				move(this.barrelAngle + 90, this.bulletSpeed)
			]);
			this.shouldRotate = false;
			setTimeout(() => (this.shouldRotate = true), this.fireRotatePause);
		};

		onUpdate(() => {
			if (this.shouldRotate) {
				this.setBarrelAngle(
					(this.barrelAngle +=
						dt() * this.rotationSpeed * (this.clockwise ? 1 : -1))
				);
			}
		});
	}
}
