export default function LoadTiles() {
    loadSprite("tileGrass1", new URL("/static/images/tiles/tileGrass1.png", import.meta.url).href);
    loadSprite("tileGrass2", new URL("/static/images/tiles/tileGrass2.png", import.meta.url).href);
    loadSprite("tileGrass_roadCornerLL", new URL("/static/images/tiles/tileGrass_roadCornerLL.png", import.meta.url).href);
    loadSprite("tileGrass_roadCornerLR", new URL("/static/images/tiles/tileGrass_roadCornerLR.png", import.meta.url).href);
    loadSprite("tileGrass_roadCornerUL", new URL("/static/images/tiles/tileGrass_roadCornerUL.png", import.meta.url).href);
    loadSprite("tileGrass_roadCornerUR", new URL("/static/images/tiles/tileGrass_roadCornerUR.png", import.meta.url).href);
    loadSprite("tileGrass_roadCrossing", new URL("/static/images/tiles/tileGrass_roadCrossing.png", import.meta.url).href);
    loadSprite("tileGrass_roadCrossingRound", new URL("/static/images/tiles/tileGrass_roadCrossingRound.png", import.meta.url).href);      
    loadSprite("tileGrass_roadEast", new URL("/static/images/tiles/tileGrass_roadEast.png", import.meta.url).href);
    loadSprite("tileGrass_roadNorth", new URL("/static/images/tiles/tileGrass_roadNorth.png", import.meta.url).href);
    loadSprite("tileGrass_roadSplitE", new URL("/static/images/tiles/tileGrass_roadSplitE.png", import.meta.url).href);
    loadSprite("tileGrass_roadSplitN", new URL("/static/images/tiles/tileGrass_roadSplitN.png", import.meta.url).href);
    loadSprite("tileGrass_roadSplitS", new URL("/static/images/tiles/tileGrass_roadSplitS.png", import.meta.url).href);
    loadSprite("tileGrass_roadSplitW", new URL("/static/images/tiles/tileGrass_roadSplitW.png", import.meta.url).href);
    loadSprite("tileGrass_roadTransitionE", new URL("/static/images/tiles/tileGrass_roadTransitionE.png", import.meta.url).href);
    loadSprite("tileGrass_roadTransitionE_dirt", new URL("/static/images/tiles/tileGrass_roadTransitionE_dirt.png", import.meta.url).href);
    loadSprite("tileGrass_roadTransitionN", new URL("/static/images/tiles/tileGrass_roadTransitionN.png", import.meta.url).href);
    loadSprite("tileGrass_roadTransitionN_dirt", new URL("/static/images/tiles/tileGrass_roadTransitionN_dirt.png", import.meta.url).href);
    loadSprite("tileGrass_roadTransitionS", new URL("/static/images/tiles/tileGrass_roadTransitionS.png", import.meta.url).href);
    loadSprite("tileGrass_roadTransitionS_dirt", new URL("/static/images/tiles/tileGrass_roadTransitionS_dirt.png", import.meta.url).href);
    loadSprite("tileGrass_roadTransitionW", new URL("/static/images/tiles/tileGrass_roadTransitionW.png", import.meta.url).href);
    loadSprite("tileGrass_roadTransitionW_dirt", new URL("/static/images/tiles/tileGrass_roadTransitionW_dirt.png", import.meta.url).href);
    loadSprite("tileGrass_transitionE", new URL("/static/images/tiles/tileGrass_transitionE.png", import.meta.url).href);
    loadSprite("tileGrass_transitionN", new URL("/static/images/tiles/tileGrass_transitionN.png", import.meta.url).href);
    loadSprite("tileGrass_transitionS", new URL("/static/images/tiles/tileGrass_transitionS.png", import.meta.url).href);
    loadSprite("tileGrass_transitionW", new URL("/static/images/tiles/tileGrass_transitionW.png", import.meta.url).href);
    loadSprite("tileSand1", new URL("/static/images/tiles/tileSand1.png", import.meta.url).href);
    loadSprite("tileSand2", new URL("/static/images/tiles/tileSand2.png", import.meta.url).href);
    loadSprite("tileSand_roadCornerLL", new URL("/static/images/tiles/tileSand_roadCornerLL.png", import.meta.url).href);
    loadSprite("tileSand_roadCornerLR", new URL("/static/images/tiles/tileSand_roadCornerLR.png", import.meta.url).href);
    loadSprite("tileSand_roadCornerUL", new URL("/static/images/tiles/tileSand_roadCornerUL.png", import.meta.url).href);
    loadSprite("tileSand_roadCornerUR", new URL("/static/images/tiles/tileSand_roadCornerUR.png", import.meta.url).href);
    loadSprite("tileSand_roadCrossing", new URL("/static/images/tiles/tileSand_roadCrossing.png", import.meta.url).href);
    loadSprite("tileSand_roadCrossingRound", new URL("/static/images/tiles/tileSand_roadCrossingRound.png", import.meta.url).href);
    loadSprite("tileSand_roadEast", new URL("/static/images/tiles/tileSand_roadEast.png", import.meta.url).href);
    loadSprite("tileSand_roadNorth", new URL("/static/images/tiles/tileSand_roadNorth.png", import.meta.url).href);
    loadSprite("tileSand_roadSplitE", new URL("/static/images/tiles/tileSand_roadSplitE.png", import.meta.url).href);
    loadSprite("tileSand_roadSplitN", new URL("/static/images/tiles/tileSand_roadSplitN.png", import.meta.url).href);
    loadSprite("tileSand_roadSplitS", new URL("/static/images/tiles/tileSand_roadSplitS.png", import.meta.url).href);
    loadSprite("tileSand_roadSplitW", new URL("/static/images/tiles/tileSand_roadSplitW.png", import.meta.url).href);
}