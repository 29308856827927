import { GameObj } from "kaboom";

export default class Map {
	tiles: GameObj[][];
	tileSize: number;

	// How many tiles should be across the screen
	tileCountX = 10;

	constructor() {
		this.tiles = [];

		this.tileSize = window.innerWidth / this.tileCountX;

		for (let x = 0; x < window.innerWidth; x += this.tileSize) {
			for (let y = window.innerHeight; y > 0; y -= this.tileSize) {
				const tile = add([
					sprite(`tileGrass${Math.floor(Math.random() * 2) + 1}`, {
						width: this.tileSize,
						height: this.tileSize
					}),
					pos(x + this.tileSize / 2, y - this.tileSize / 2),
					// @ts-ignore
					origin("center"),
					z(-2),
					"mapTile"
				]);
				const xNorm = Math.round(x / this.tileSize);
				const yNorm = Math.round(
					(window.innerHeight - y) / this.tileSize
				);

				// console.log({ x, y, xNorm, yNorm });

				if (!this.tiles[xNorm]) this.tiles.push([]);
				this.tiles[xNorm].push(tile);
			}
		}
	}

	getTile(x: number, y: number) {
		return this.tiles[x][y];
	}

	setTile(x: number, y: number, value: GameObj) {
		this.tiles[x][y] = value;
	}

	async path(x: number, y: number) {
		// this.getTile(x, y).use(
		// 	sprite("tileGrass_roadNorth", {
		// 		width: this.tileSize,
		// 		height: this.tileSize
		// 	})
		// );


		const currPos = { x, y };
		let currAngle = 0;
		let tileName = "tileGrass_roadNorth";
		while (currPos.y < this.tiles[0].length) {

			let r = Math.random();
			// If you're facing up
			if (currAngle === 0) {
				/* If currently on an edge, we don't want to turn into the edge, 
				   so keep on generating random numbers until we're not going to 
				   turn into the edge. */
				if (currPos.x === 0) {
					while (r > 0.5 && r <= 0.75) {
						console.log("0 deg redoing random angle number 1!")
						r = Math.random();
					}
				} else if (currPos.x === this.tileCountX - 1) {
					while (r > 0.75) {
						console.log("0 deg redoing random angle number 2!")
						r = Math.random();
					}
				}

				if (r <= 0.5) {
					spawnPath(this, currPos, "tileGrass_roadNorth")
					currPos.y++;
					currAngle = 0;
					console.log(11)
				} else if (r <= 0.75) {
					spawnPath(this, currPos, "tileGrass_roadCornerLL")

					currPos.x--;
					currAngle = 270;
					console.log(22)
				} else {
					spawnPath(this, currPos, "tileGrass_roadCornerLR");

					currPos.x++;
					currAngle = 90;
					console.log(33)
				}
			} else if (currAngle === 270) {
				if (currPos.x === 0) {
					while (r > 0.5) {
						console.log("270 deg redoing random angle!")
						r = Math.random();
					}
				}
				if (r <= 0.5) {
					spawnPath(this, currPos, "tileGrass_roadCornerUR");
					currPos.y++;
					currAngle = 0;
					console.log(44)
				} else {
					spawnPath(this, currPos, "tileGrass_roadEast");
					currPos.x--;
					currAngle = 270;
					console.log(55)
				}
			} else if (currAngle === 90) {
				if (currPos.x === this.tileCountX - 1) {
					while (r > 0.5) {
						console.log("90 deg redoing random angle!")
						r = Math.random();
					}
				}

				if (r <= 0.5) {
					spawnPath(this, currPos, "tileGrass_roadCornerUL");
					currPos.y++;
					currAngle = 0;
					console.log(66)
				} else {
					spawnPath(this, currPos, "tileGrass_roadEast");
					currPos.x++;
					currAngle = 90;
					console.log(77)
				}
			} else {
				console.error("EAFFFFFFFFFF")
			}

			await new Promise(r => setTimeout(r, 5));
		}
	}
}

function spawnPath(t, currPos, tileName) {
	console.log(t)
	t.getTile(currPos.x, currPos.y).use(
		sprite(tileName, {
			width: t.tileSize,
			height: t.tileSize
		})
	);
}