import kaboom from "kaboom";
import LoadAssets from "./utils/loader";

import Map from "./core/map";
import Tank from "./core/tank";

kaboom({ background: [255, 0, 255] });
LoadAssets();

const map = new Map();
map.path(5, 0);

const tankPos = map.getTile(5, 0).pos;
const tank = new Tank(tankPos, 0.005 * map.tileSize);

// TODO: Move to tank class?
onClick(() => tank.click());
onTouchStart(() => tank.click());

// onDraw(() => {
//     drawLine({
//         p1: vec2(0),
//         p2: mousePos(),
//         width: 4,
//         color: rgb(0, 0, 255),
//     })
// })
