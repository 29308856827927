// TODO: Set root? Doesn't work atm.

import LoadTiles from "./loaders/tiles";

export default function LoadAssets() {
	LoadTiles();

	// Shots
	loadSprite(
		"shotThin",
		new URL("/static/images/shots/shotThin.png", import.meta.url).href
	);

	// Tanks
	loadSprite(
		"tankBodyGreenOutline",
		new URL(
			"/static/images/tanks/tankBody_green_outline.png",
			import.meta.url
		).href
	);
	loadSprite(
		"tankGreenBarrel1",
		new URL(
			"/static/images/tanks/tankGreen_barrel1_outline.png",
			import.meta.url
		).href
	);
	loadSprite(
		"tankGreenBarrel2",
		new URL(
			"/static/images/tanks/tankGreen_barrel2_outline.png",
			import.meta.url
		).href
	);
	loadSprite(
		"tankGreenBarrel3",
		new URL(
			"/static/images/tanks/tankGreen_barrel3_outline.png",
			import.meta.url
		).href
	);
}
